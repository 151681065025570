.buttonMobileMenu {
  display: none;
  border-radius: 67px;
  width: 50%;
  color: red;
}

@media screen and (max-width: 975px) {
  .buttonMobileMenu {
    display: block;
  }
  .infoCreditosMobile {
    display: block;
  }
  .infoCreditos {
    display: none;
  }
  .displaySidebar {
    display: none;
  }
}

@media screen and (min-width: 976px) {
  .buttonMobileMenu {
    display: none;
  }
  .infoCreditosMobile {
    display: none;
  }
  .infoCreditos {
    display: block;
  }
  .displaySidebar {
    display: block;
  }
}