.payment {
  background-color: #f2f2f2;
  padding: 1em;
  border: 2px solid cornflowerblue;
}

.paymentFormatting {
  text-align: center;
}

.breadCrumbContinuarComprar {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  font-size: 15px;
  text-align: center;
}

.logoPayment {
  width: 55%;
}

.SelectField label {
  position: absolute !important;
  line-height: 20px;
  width: 100% !important;
  z-index: 1;
  color: rgba(0, 0, 0, 0.3);
  transform: scale(1) translate(0px, -30px) !important;
  left: 0px !important;
}

.circularProgress {
  margin-left: 10px;
}