.textFieldFontStyle, .textFieldFontStyle input, .textFieldFontStyle label {
    font-family: "Montserrat", sans-serif!important;
    font-weight: 500;
}

.registerTitleParent {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
}

.registerTitle {
    font-family: "Montserrat", sans-serif!important;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
    color: rgba(71, 73, 79, 1);
}

.registerContainer {
    width: 100%;
    margin: auto
}

.inputsParent {
    /* margin-bottom: 15px; */
}

.inputsParent .inputContainer {
    margin-bottom: 15px;
    text-align: left;
}

.inputsParent .inputContainer.dateInputContainer {
    margin-top: 25px;
}

button.registerButton {
    background-color: #CA006A;
    color: white;
    width: 70%;
    font-family: "Montserrat";
    font-weight: 500
}

button.registerButton:hover {
    background-color: #a82368;
    color: white;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
    .inputsParent {
        margin-bottom: 25px;
    }
    .inputsParent .inputContainer {
        margin-bottom: 0px;
    }
    .inputsParent .inputContainer.dateInputContainer {
        margin-top: 0px;
    }
    button.registerButton {
        width: 60%;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .registerContainer {
        width: 75%;
    }
    .inputsParent {
        margin-bottom: 25px;
    }
    .inputsParent .inputContainer {
        margin-bottom: 0px;
    }
    .inputsParent .inputContainer.dateInputContainer {
        margin-top: 0px;
    }
    button.registerButton {
        width: 45%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .inputsParent {
        margin-bottom: 25px;
    }
    .inputsParent .inputContainer {
        margin-bottom: 0px;
    }
    .inputsParent .inputContainer.dateInputContainer {
        margin-top: 0px;
    }
    button.registerButton {
        width: 30%;
    }
}