.textFieldFontStyle, .textFieldFontStyle input, .textFieldFontStyle label {
    font-family: "Montserrat", sans-serif!important;
    font-weight: 500;
}

.changePasswordTitleParent {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
}

.changePasswordTitle {
    font-family: "Montserrat", sans-serif!important;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
    color: rgba(71, 73, 79, 1);
}

button.changePasswordButton {
    background-color: #CA006A;
    color: white;
    font-family: "Montserrat";
    font-weight: 500;
}

button.changePasswordButton:hover {
    background-color: #972761;
}

.changePasswordContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.changePasswordSubContainer {
    padding: 0px;
}