.text404 {
    font-size: 22px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    color: #004579;
    line-height: 1.5;
    letter-spacing: 0px;
}

.text404 a {
    color: #004579;
}

.boldText404, a.boldText404 {
    font-weight: bold;
}

.imgParent {
    display: flex;
    justify-content: center;
}

.imgContainer {
    width: 100%;
}

.img404 {
    width: 100%;
    height: auto;
}

.customSpacing404 {
    display: block;
    margin-bottom: 16px;
}

.smallerText404 {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    color: #004579;
    line-height: 1.5;
    letter-spacing: 0px;
}

.smallerText404 a {
    color: #004579;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .img404 {
        width: 60%;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .img404 {
        width: 55%;
    }
}

@media (min-width: 1100px) {
    .img404 {
        width: 63%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1700px) {
    .img404 {
        width: 45%;
    }
}