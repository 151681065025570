.successBox h5 {
  color: rgb(202, 0, 106) !important;
}

.successBox .btn-selecionar {
  color: rgb(255, 255, 255);
  background-color: rgb(202, 0, 106);
}

.successBox .cancelarConfirmacao {
  color: rgb(220, 0, 78);
  background-color: rgb(202, 0, 106) !important;
}

.successBox .avancar {
  color: rgb(255, 255, 255);
  background-color: rgb(202, 0, 106) !important;
}

.successBox .description {
  color: rgb(202, 0, 106) !important;
  font-size: 18px;
  font-weight: 300;
}
