/* Body */

/* Utils */

/* Tipos de letra para o website */

@import url("https://fonts.googleapis.com/css?family=Questrial");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed");
/* Tipo de letra do website  */

:root {
  --website-font-family: "Montserrat", sans-serif;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

p {
  color: rgba(3, 66, 117, 1);
  font-size: 1em;
  line-height: 2.3em;
}

a {
  color: rgb(202, 0, 106);
}

a:active {
  color: rgb(136, 4, 72);
}

h1 {
  color: rgb(202, 0, 106);
}

h2 {
  color: rgb(202, 0, 106);
  font-size: 1.5em !important;
}

h3 {
  color: rgb(202, 0, 106);
}

.strongItems {
  color: rgb(202, 0, 106);
  font-weight: 500;
}

.colorPink {
  color: rgb(202, 0, 106);
}

.justify {
  text-align: justify;
}

.center {
  text-align: center;
}

.cta {
  background-color: #ca006a;
  color: white;
  width: 25vw;
  padding: 20px;
  min-width: 200px;
  border-radius: 7px;
}

.cta:hover {
  background-color: #ca036b99;
}

.cta h3 {
  font-size: 1.4em !important;
  color: white;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.footer p {
  color: rgba(3, 66, 117, 1);
  font-size: 1em;
  line-height: 1em;
}

/* HOME */

/**/

/* Texto para uppercase */

.uppercase {
  text-transform: uppercase;
}

.justify {
  text-align: justify;
}

.center {
  text-align: center;
}

/* Alinhar à direita */

.right-align {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.hide {
  display: none !important;
}

.incentivos .subtitle {
  font-size: 15px;
  font-weight: bold;
}

.tableReact {
  font-size: 10px;
}

@media only screen and (max-width: 480px) {
  .xs-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .sm-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  .md-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 980px) {
  .lg-hide {
    display: none !important;
  }
}

.checkoutTiposPagamento {
  background-color: #f2f2f2;
  padding: 15px;
  text-align: center;
}

.checkoutTiposPagamento:hover {
  background-color: #ffffff;
  border: 1px solid red;
}

.checkoutTiposEnvio {
  background-color: #f2f2f2;
  padding: 15px;
  text-align: center;
}

.checkoutTiposEnvio:hover {
  background-color: #ffffff;
  border: 1px solid red;
}

.checkoutSelectedPagamentoEnvio {
  background-color: #ffffff;
  border: 1px solid red;
}

.dadosFaturacaoEnvio {
  background-color: #f2f2f2;
  padding: 1em;
}

.checkoutValidacaoPromocional {
  text-align: center;
}

.breadCrumbContinuarComprar {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  font-size: 15px;
  text-align: center;
}

.detailProductPrice {
  color: red;
}

.detailProductStar {
  color: #ffcf00;
}

.detailProductImage {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
}

.buttonFfetuarPagamentoCarrinho {
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  font-size: 15px;
  text-align: center;
}

.productImageCart {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.paymentMultibanco {
  background-color: #f2f2f2;
  padding: 1em;
  border: 2px solid cornflowerblue;
}

.paymentMultibancoFormatting {
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}

.loginCentered {
  justify-content: center;
  align-items: center;
}

/* Product GRID */

.badgePromotion {
  z-index: 1000;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 58px;
}

.promotionPrice {
  color: red;
}

.buttonBuy {
  border: 2px #989595a8 !important;
  border-radius: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 60px;
  background-color: #b1afaf42;
}

.buttonBuy:hover {
  border: 2px #989595a8 !important;
  border-radius: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 60px;
  background-color: #3785c157;
}

.priceProduct {
  border: 2px #989595a8 !important;
  border-radius: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  left: 16px;
  width: -5px;
  background-color: #f3f3f3;
}

.colorShoppingCart {
  color: #3e92cc !important;
}

/* Toast */

.myToast {
  font-family: var(--website-font-family) !important;
  color: white;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  z-index: 99999 !important;
}

.Toastify__toast-container {
  z-index: 99999 !important;
}

@media only screen and (max-width: 480px) {

  .Toastify__toast-container {
    bottom: 3rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    width: 100%;
  }
}

.toast-title {
  font-weight: 700;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #fff;
}

.toast-message a:hover {
  color: #ccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.toast-close-button:focus,
.toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 2000000008 !important;
  pointer-events: none;
}

#toast-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container>div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  color: #fff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container> :hover {
  -webkit-box-shadow: 0 0 12px #000;
  box-shadow: 0 0 12px #000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container>.toast-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important;
}

#toast-container>.toast-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important;
}

#toast-container>.toast-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
}

#toast-container>.toast-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important;
}

#toast-container.toast-bottom-center>div,
#toast-container.toast-top-center>div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-bottom-full-width>div,
#toast-container.toast-top-full-width>div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

.ihtpLink {
  color: hsla(215, 100%, 56%, 1);
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
}

.ihtpLink:hover {
  text-decoration: underline;
}

.ihtpInputAction {
  font-size: 13px;
  cursor: pointer;
}

@media all and (max-width: 240px) {
  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container>div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

.marginBetweenMenu {
  margin-top: 1%;
}

#mobile-header {
  background-color: #044375;
  /* Altura máxima do header, não se deve meter em vh por causa dos diferentes dispositivos */
  height: 60px;
}

@media screen and (max-width: 600px) {
  .mobile-container {
    position: absolute;
    top: 60px;
    min-width: 100vw;
  }
}

@media screen and (min-width: 600px) {
  .mobile-container {
    display: none;
  }
}

/* Para todo o projecto em mobile */

.mobileContainer {
  margin-top: 65px;
  margin-left: 1vw;
  margin-right: 1vw;
  padding: 1vw;
  max-width: 98vw;
  width: 98vw;
  display: -webkit-flex;
  display: flex;
}

/* Institucional */

/* generico */

.destaque-rosa {
  color: #d0177b;
  font-weight: 100;
}

.texto-institucional {
  line-height: 35px;
  text-align: justify;
}

/*Porque IHTP */

.destaque-academias {
  color: #d0177b;
  font-weight: 300;
}

.ReactTable .-pagination .-btn {
  background-color: #ffffff !important;
  color: "white" !important;
  border-radius: 5px !important;
}

.ReactTable .-pagination .-btn:hover {
  background-color: #d0177beb !important;
  color: "white" !important;
}

.ReactTable .rt-th {
  white-space: inherit !important;
}

.ReactTable .rt-tr:hover .rt-td .tableDeleteButto {
  display: inline-block;
}

@media (min-width: 1200px) {
  .container {
    max-width: 100vw !important;
  }
}

/* ADICIONA POR AFONSO PARA TESTE */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #034275;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ADICIONADO PARA TESTE DO REACT PLAYER */

.wrapper {
  position: relative;
  padding-top: 56.25%;
}

.facebookIcon:hover {
  transform: scale(1.15);
}

.instaIcon:hover {
  transform: scale(1.15);
}

.youtubeIcon:hover {
  transform: scale(1.15);
}

.linkdinIcon:hover {
  transform: scale(1.15);
}

.aComprar:hover {
  text-decoration: none;
}

.academiasIcons:hover {
  transform: scale(1.1);
}

.buttonProgress {
  color: green;
  position: "absolute";
  top: "50%";
  left: "50%";
  margin-top: -12;
  margin-left: -12;
}

.ReactTable .rt-tr:hover .altText {
  overflow: visible;
}

.fake-link {
  color: rgb(202, 0, 106);
  text-decoration: underline;
  cursor: pointer;
}

.fake-linkDownloads {
  color: rgb(0, 123, 255);
  cursor: pointer;
}

.fake-linkDownloads:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 500px) {
  .containerLP {
    max-width: 100vw !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 500px) {
  .containerLP {
    max-width: 100vw !important;
  }
}

.fullPageLoading {
  transition: opacity 0.3s,
}

.mainContentContainer {
  position: relative;
  top: 80px;
  width: 90vw;
  /* height:  */
  min-height: calc(95vh - 80px);
  ;
}