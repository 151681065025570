.bp3-md-header {
  top: 0px;
  width: 100vw;
  min-width: 1366px;
  height: 60px;
  min-height: 60px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(3, 66, 117, 1);
}

.bp3-md-header .bp3-headermobile {
  height: 60px;
  position: relative;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-rectangle {
  background-color: rgba(3, 66, 117, 1);
  top: 0px;
  height: 60px;
  width: 100vw;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-logoihtp {
  background-color: rgba(255, 255, 255, 0);
  top: 10px;
  height: 41px;
  width: 204px;
  position: relative;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-logoImage {
  height: 14px;
  width: 100vw;
  position: relative;
  margin: 0;
  text-align: center;
}

.bp3-md-header .bp3-headermobile .bp3-shoppingcart {
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  margin: 0;
  right: 25px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-shoppingcart .bp3-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 25px;
  width: 24px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-profile {
  background-color: rgba(255, 255, 255, 0);
  height: 24px;
  width: 25px;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-profile .bp3-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 14px;
  width: 14px;
  position: absolute;
  margin: 0;
  left: 6px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-profile .bp3-shape1 {
  background-color: rgba(255, 255, 255, 0);
  top: 15px;
  height: 9px;
  width: 25px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-line {
  background-color: rgba(255, 255, 255, 0);
  top: 35px;
  position: absolute;
  margin: 0;
  left: 5vw;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-line1 {
  background-color: rgba(255, 255, 255, 0);
  top: 29px;
  height: 3px;
  width: 26px;
  position: absolute;
  margin: 0;
  left: 5vw;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-headermobile .bp3-line2 {
  background-color: rgba(255, 255, 255, 0);
  top: 22px;
  height: 3px;
  width: 26px;
  position: absolute;
  margin: 0;
  left: 5vw;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-header .bp3-divshoppingProfile {
  background-color: rgba(255, 255, 255, 0);
  top: 18px;
  height: 24px;
  width: 55px;
  position: absolute;
  margin: 0;
  right: 5vw;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

/* .anima-animate-appear prefix for appear animations */

@-webkit-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.numberItensShoppingCart {
  z-index: 1001;
  background-color: red;
  position: absolute;
  left: 15px;
  top: 12px;
  border-radius: 25%;
  padding: 2px;
}

.formSearch {
  transform: translate(-50%, -50%);
  transition: all 1s;
  width: 50px;
  height: 50px;
  background: white;
  box-sizing: border-box;
  border-radius: 25px;
  border: 4px solid white;
  padding: 5px;
}

.inputSearch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42.5px;
  line-height: 30px;
  outline: 0;
  border: 0;
  display: none;
  font-size: 1em;
  border-radius: 20px;
  padding: 0 20px;
}

.fa {
  box-sizing: border-box;
  padding: 10px;
  width: 42.5px;
  height: 42.5px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: #07051a;
  text-align: center;
  font-size: 1.2em;
  transition: all 1s;
}

.formSearch:hover {
  width: 200px;
  cursor: pointer;
}

.formSearch:hover .inputSearch {
  display: block;
}

.formSearch:hover .fa {
  background: #07051a;
  color: white;
}

.pesquisar {
  margin-top: -7px
}

/* .pesquisar:hover .pesquisarTexto {
  display: block
} */

.MuiInputBase-root-28 .pesquisarTexto {
  display: none
}

a .buttonLabels {
  color: #ffffff;
  font-size: 14px;
  display: initial;
  text-decoration: none!important;
  margin: 0px 0px 0px 11px;
  cursor: pointer;
}

a .buttonLabels:visited {
  text-decoration: none!important;
}

a .buttonLabels:link {
  text-decoration: none!important;
}

a .buttonLabels:hover {
  text-decoration: underline;
  cursor: pointer;
}

a .buttonLabels:active {
  text-decoration: none!important;
}

@media screen and (max-width: 1160px) {
  a .buttonLabels {
    display: none;
  }
}

.customHr {
  background-color: red;
}

.headerTitleContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.headerNewSiteMenusContainer {
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: #ffff;
  font-size: 12px;
  font-family: "Rubik", Sans-serif;
  font-weight: 500;
}

.headerNewSiteMenusSubContainer {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  min-width: 100%;
}

.headerNewSiteMainLinkContainer {
  padding: 0px 5px 0px 0px;
  text-align: right;
}

.headerNewSiteStoreLinkContainer {
  padding: 0px 5px 0px 0px;
  text-align: right;
}

.headerLoginTitle, .headerLogoutTitle {
  font-size: 13px;
  font-family: "Rubik", Sans-serif;
  font-weight: 500;
  margin-left: 3px;
}

.headerActionsMenusContainer {
  display: inline-flex;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 10px 0px 0px;
}

.selectLanguageContainer {
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}

.selectLanguageInput {
  text-align-last: center;
  padding: 10px;
  min-width: 100%;
  font-family: "Rubik";
  font-size: 15px;
}

.selectCurrencyContainer {
  display: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-right: 35px;
}

.selectCurrencyInput {
  font-family: "Rubik";
  font-size: 15px;
  text-align-last: center;
  padding: 10px;
  color: white;
  min-width: 100%;
  box-shadow: none;
}

.headerEmptySpace {
  display: none;
  padding: 0px;
}

.headerMenu {
  width: 100vw;
  position: fixed;
  height: 80px;
  z-index: 80;
}

.headerMainContainer {
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
}

.headerMainSubContainer {
  min-width: 95vw;
  max-width: 95vw;
  height: 80px;
}

.headerLinks {
  color: white;
}

.headerLinks:hover {
  color: white;
}

.headerMainMenuButton {
  display: block;
}

.headerTitleSubContainer {
  display: inline-flex;
  align-items: center;
}

.headerLogoutButtonContainer {
  display: none;
  padding: 0px 15px 0px 10px;
}

.bp3-md-menu .selectCurrencyContainer {
  display: flex;
  padding-right: 0px;
}

.bp3-md-menu .selectLanguageContainer {
  display: flex;
  padding-right: 0px;
}

/* Small devices */

@media (min-width: 576px) {
  .headerEmptySpace {
    display: none;
  }
  .headerNewSiteMenusSubContainer {
    justify-content: center;
  }
  .headerNewSiteMainLinkContainer {
    text-align: right;
  }
  .headerNewSiteStoreLinkContainer {
    text-align: right;
  }
  .headerNewSiteMenusContainer {
    display: none;
  }
  .headerActionsMenusContainer {
    justify-content: flex-end;
  }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .headerMainMenuButton {
    display: block;
  }
  .logoimage {
    width: 100%;
  }
  .headerNewSiteMenusSubContainer {
    justify-content: flex-end;
  }
  .headerNewSiteMainLinkContainer {
    text-align: center;
  }
  .headerNewSiteStoreLinkContainer {
    text-align: center;
  }
  .headerEmptySpace {
    display: none;
  }
  .headerLogoutButtonContainer {
    display: block;
  }
  .headerNewSiteMenusContainer {
    display: flex;
  }
  .headerNewSiteMenusContainer {
    display: none;
  }
  .headerActionsMenusContainer {
    justify-content: flex-end;
    padding: 0px;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .headerMainSubContainer {
    min-width: 90vw;
    max-width: 90vw;
  }
  .headerMainMenuButton {
    display: none;
  }
  .headerEmptySpace {
    display: block;
  }
  .headerLogoutButtonContainer {
    display: block;
  }
  .selectCurrencyContainer {
    display: flex;
  }
  .selectLanguageContainer {
    display: flex;
  }
  .headerNewSiteMenusContainer {
    display: flex;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .headerMainMenuButton {
    display: none;
  }
  .headerNewSiteMainLinkContainer {
    text-align: left;
  }
  .headerNewSiteStoreLinkContainer {
    text-align: left;
  }
  .headerTitleContainer {
    text-align: left;
    justify-content: left;
  }
  .headerNewSiteMenusContainer {
    display: flex;
    justify-content: left;
  }
  .headerLogoutButtonContainer {
    display: block;
  }
}