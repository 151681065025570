#botaoRecibo:hover {
    transform: scale(1.1)
}

#cancelButton:hover {
    transform: scale(1.1);
    fill: red
}

#botaoFatura:hover {
    transform: scale(1.1)
}

#botaoNotaCredito:hover {
    transform: scale(1.1)
}

#botaoPagamento:hover {
    transform: scale(1.1)
}