.myaccount-tab-menu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #ffffff;
}
.myaccount-tab-menu button {
  border: 1px solid #eeeeee;
  border-bottom: none;
  color: #7a7a7a;
  font-weight: 500;
  font-size: 12px;
  display: block;
  padding: 15px 15px 13px;
  text-transform: uppercase;
}
.myaccount-tab-menu button:last-child {
  border-bottom: 1px solid #eeeeee;
}
.myaccount-tab-menu button:hover,
.myaccount-tab-menu button.active {
  background-color: #3785c1;
  color: #ffffff;
}
.myaccount-tab-menu button i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

/*-- My Account Content -*/
.myaccount-content {
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid #eeeeee;
  padding: 30px;
}
@media only screen and (max-width: 575px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}
.myaccount-content h3 {
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.myaccount-content .welcome a {
  color: #000000;
}
.myaccount-content .welcome a:hover {
  color: #3785c1;
}
.myaccount-content .welcome strong {
  font-weight: 600;
}
.myaccount-content a.edit-address-btn {
  background: none;
  border: none;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #363f4d;
  border-color: #363f4d;
  padding: 10px 20px;
  border-radius: 3px;
}
.myaccount-content a.edit-address-btn i {
  padding-right: 5px;
}
.myaccount-content a.edit-address-btn:hover {
  background-color: #3785c1;
}
.myaccount-content button.save-change-btn {
  background: none;
  border: none;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #363f4d;
  border-color: #363f4d;
  width: 140px;
  padding: 10px 0;
  border-radius: 3px;
}
.myaccount-content button.save-change-btn:hover {
  background-color: #3785c1;
}

/*-- My Account Table -*/
.myaccount-table {
  white-space: nowrap;
  font-size: 15px;
}
.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
}
.myaccount-table table td,
.myaccount-table .table td {
  padding: 20px 10px;
  vertical-align: middle;
}
.myaccount-table table td a:hover,
.myaccount-table .table td a:hover {
  color: #3785c1;
}

.saved-message {
  border-top: 3px solid #3785c1;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  font-size: 13px;
  padding: 20px;
}

/*-- My Account Details Form -*/
.account-details-form h4 {
  margin: 0;
}

.account-details-form input {
  display: block;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 0;
  line-height: 24px;
  padding: 11px 25px;
  color: #656565;
}
